<template>
  <div class="badBeltBGView">
    <div class="badBelt">
      <bad-belt-operation></bad-belt-operation>
      <socket-status
          :link-w-i-f-i="linkInfo.linkWIFI"
          :link-bad-belt="linkInfo.linkBadBelt"
          :link-w-i-f-i-name="linkInfo.linkWIFIName"
      >
      </socket-status>

      <socket-config
          @updateInfo="updateConfig"
          @updateConfig="updateConfig"
          @resetSocketConfig="resetSocketConfig"
          @saveSocketConfig="saveSocketConfig"
          @showInPutView="showInPutView"
          @sendSocketConfig="sendConfigToBadBelt"
          store-key="setBadBeltConfig"
          :config-list="configList"
      >
      </socket-config>

      <socket-info :config-list="dataInfoList" @openSocket="openSocket" @refreshSocket="refreshSocket">
      </socket-info>

      <log :id="'badBeltLog'" :ref="'badBeltLog'"></log>
    </div>
    <in-put v-if="showInPut" :in-put-info="inPutInfo" @updateInfo="updateConfig"></in-put>

  </div>
</template>

<script>
import BadBeltOperation from '@/views/bedBelt/badBeltOperation/badBeltOperation'
import SocketStatus from '@/views/socket/socketStatus'
import SocketConfig from '@/views/socket/socketConfig'
import SocketInfo from '@/views/socket/socketInfo'
import Log from '@/components/log/log'
import InPut from '@/components/inPut/inPut'

export default {
  name: 'badBelt',
  components: { InPut, Log, SocketInfo, SocketConfig, SocketStatus, BadBeltOperation },
  data() {
    return {
      showInPut: false,
      inPutInfo: {},
      linkInfo: {
        linkWIFI: false,
        linkBadBelt: false,
        linkWIFIName: '',
        linkBadBeltStatus: 'connect_fail'
      },
      dataInfoList: [{
        title: 'WIFI_SSID',
        content: '',
        dataKey: 'ssid',
        matchType: 'all'
      }, {
        title: 'WIFI密码',
        content: '',
        dataKey: 'password',
        matchType: 'all'
      }, {
        title: '服务器',
        content: '',
        dataKey: 'service',
        matchType: 'all'
      }, {
        title: '服务器端口',
        content: '',
        dataKey: 'port',
        matchType: 'onlyInt'
      }, {
        title: '类型',
        content: '',
        dataKey: 'class',
        matchType: 'onlyBool'
      }],
      configList: [{
        title: 'WIFI_SSID',
        content: 'W2_TP_LINK_6766',
        dataKey: 'ssid',
        matchType: 'all'
      }, {
        title: 'WIFI密码',
        content: 'mnmnmnmn',
        dataKey: 'password',
        matchType: 'all'
      }, {
        title: '服务器',
        content: 'catiottest-cloud.com',
        dataKey: 'service',
        matchType: 'all'
      }, {
        title: '服务器端口',
        content: '8067',
        dataKey: 'port',
        matchType: 'onlyInt'
      }, {
        title: '类型',
        content: '0',
        dataKey: 'class',
        matchType: 'onlyBool'
      }]
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.loadSocketConfig()
      this.linkSocket()
    })
  },
  beforeDestroy() {
    this.closeSocket()
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'login') {
      next(true)
      return;
    }
    if (this.showInPut) {
      this.showInPut = false
      next(false)
      return
    }
    next(true)
  },
  watch: {
    'linkInfo.linkBadBeltStatus': {
      handler(value) {
        switch (value) {
          case 'connect_fail':
            this.linkInfo.linkBadBelt = false
            this.addLog('连接失败')
            break
          case 'connect_success':
            this.linkInfo.linkBadBelt = true
            this.addLog('连接成功')
            this.flutter.socketSend('A04')
            break
          case 'socket_un_initialized':
            this.linkInfo.linkBadBelt = false
            this.addLog('未初始化')
            break
          case 'send_msg_error':
            this.linkInfo.linkBadBelt = true
            this.addLog('发送消息失败')
            break
          case 'host_port_null':
            this.addLog('参数错误')
            break
          default:
            break
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    addLog: function(msg) {
      const divId = 'badBeltLog'
      if (typeof this.$refs[divId] == 'undefined') {
        return
      }
      this.$refs[divId].updateLogList(msg)
    },
    showInPutView: function(inPutInfo) {
      this.showInPut = true
      this.inPutInfo = inPutInfo
    },

    loadSocketConfig: function() {
      let bedBeltConfigStr = localStorage.getItem('babBelt')
      if (typeof bedBeltConfigStr == 'undefined' || bedBeltConfigStr == null) {
        return
      }
      try {
        this.updateConfig(JSON.parse(bedBeltConfigStr))
      } catch (e) {

      }
    },
    resetSocketConfig: function() {
      this.configList = [{
        title: 'WIFI_SSID',
        content: 'W2_TP_LINK_6766',
        dataKey: 'ssid',
        matchType: 'all'
      }, {
        title: 'WIFI密码',
        content: 'mnmnmnmn',
        dataKey: 'password',
        matchType: 'all'
      }, {
        title: '服务器',
        content: 'catiottest-cloud.com',
        dataKey: 'service',
        matchType: 'all'
      }, {
        title: '服务器端口',
        content: '8067',
        dataKey: 'port',
        matchType: 'onlyInt'
      }, {
        title: '类型',
        content: '0',
        dataKey: 'class',
        matchType: 'onlyBool'
      }]
      this.saveSocketConfig()
      this.$message({
        type: 'success',
        message: '重置成功'
      })
    },
    saveSocketConfig: function() {
      let babBeltConfig = {}
      for (let i = 0; i < this.configList.length; i++) {
        let info = this.configList[i]
        babBeltConfig[info.dataKey] = info.content
      }
      localStorage.setItem('babBelt', JSON.stringify(babBeltConfig))
      this.sendConfigToBadBelt()
    },
    sendConfigToBadBelt: function() {
      let findValue = (list, key) => {
        let value = ''
        for (let i = 0; i < list.length; i++) {
          let info = list[i]
          if (info.dataKey == key) {
            value = info.content
            break
          }
        }
        return value
      }

      let promiseAction = (time, callBack) => {
        return new Promise(resolve => {
          callBack()
          setTimeout(() => {
            resolve({ result: true })
          }, time * 1000)
        })
      }

      let ssid = findValue(this.configList, 'ssid')
      let password = findValue(this.configList, 'password')
      let host = findValue(this.configList, 'service')
      let port = findValue(this.configList, 'port')
      let type = findValue(this.configList, 'class')

      let send = async() => {
        await promiseAction(1, () => {
          this.flutter.socketSend('A01,' + ssid + ',' + password)
          this.flutter.socketSend('A02,' + host + ':' + port)
        })
        await promiseAction(1, () => {
          this.flutter.socketSend('A03,' + type)
        })
        await promiseAction(1, () => {
          this.flutter.socketSend('A04')
        })
        this.$message({
          type: 'success',
          message: '保存成功'
        })
      }
      send()
    },

    updateConfig: function(dataInfo) {
      for (let i = 0; i < this.configList.length; i++) {
        let info = this.configList[i]
        if (typeof dataInfo[info.dataKey] != 'undefined') {
          info.content = dataInfo[info.dataKey]
        }
      }
      this.showInPut = false

    },
    socketSend: function(msg) {
      console.log('socketSend - ' + msg)
      this.flutter.socketSend(msg)
    },
    closeSocket: function() {
      this.flutter.closeSocket()
    },
    refreshSocket: function() {
      console.log('refreshSocket')
      this.flutter.socketSend('A04')
    },
    openSocket: function() {
      this.flutter.openSocket('192.168.4.1', 8086)
      this.addLog('开始连接 192.168.4.1:8086')
    },
    m1_result_handle(result) {
      let res = {
        result: false,
        msg: '无信息'
      }
      if (result == null) {
        return res
      }
      const results = result.split(',')
      if (results.length === 0) {
        return res
      }
      switch (results[0]) {
        case 'B01':
          res.msg = '修改Wifi成功'
          res.type = 'msg'
          res.result = true
          break
        case 'B02':
          res.msg = '修改服务器成功'
          res.type = 'msg'
          res.result = true
          break
        case 'B03':
          res.msg = '修改类型成功'
          res.type = 'msg'
          res.result = true
          break
        case 'B04':
          if (result.length < 6) {
            res.msg = '获取信息格式错误'
            res.type = 'getInfo'
            res.result = true
            res.data = {
              ssid: '',
              password: '',
              service: '',
              port: '',
              'class': ''
            }
            break
          }
          //B04,Tenda_50F480,mnmnmnmn,cattest.kang-cloud.com,8067,0
          const wifi_ssid = results[1]
          const password = results[2]
          const host = results[3]
          const port = results[4]
          const type = results[5]
          res.msg = '获取信息格式'
          res.type = 'getInfo'
          res.result = true
          res.data = {
            ssid: wifi_ssid,
            password: password,
            service: host,
            port: port,
            'class': type
          }
          break
      }
      return res
    },
    getBadBeltInfo: function(result) {
      let res = this.m1_result_handle(result)
      if (res.result == false) {
        return
      }
      this.addLog(res.msg)
      if (res.type != 'getInfo') {
        return
      }
      let data = res.data
      for (let i = 0; i < this.dataInfoList.length; i++) {
        let info = this.dataInfoList[i]
        if (typeof data[info.dataKey] == 'undefined' || data[info.dataKey] == null) {
          continue
        }
        info.content = data[info.dataKey]
      }
    },
    linkSocket: function() {
      this.flutter.getNetworkInfo();
      //网络信息回调
      this.flutter.onNetworkInfo((result) => {
        try {
          let dataInfo = JSON.parse(result);
          if (dataInfo.type != 'wifi') {
            this.linkInfo.linkWIFI = false;
            this.linkInfo.linkWIFIName = '';
            return;
          }
          this.linkInfo.linkWIFI = true;
          this.linkInfo.linkWIFIName = dataInfo.wifiName;
          if (dataInfo.wifiName.indexOf('kcloudM1_') == 0) {
            if (this.linkInfo.linkBadBelt == false) {
              this.openSocket();
            }
          } else {
            this.linkInfo.linkBadBelt = false
          }
        } catch (e) {

        }
      })
      //socket消息回调
      this.flutter.onSocketMsg((result) => {
        this.getBadBeltInfo(result)
      })
      //socket状态回调
      this.flutter.onSocketStatus((result) => {
        this.linkInfo.linkBadBeltStatus = result
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.badBeltBGView {
  width: 100%;
  height: 100%;

  .badBelt {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: darkgrey;
    overflow: scroll;
  }
}


</style>
