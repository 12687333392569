<template>
  <div class="badBeltOperationBGView">
    <div class="badBeltOperation">
      <div class="title">
        操作提示
      </div>
      <div class="step">
        <div class="stepTitle stepTitle-extension">
          步骤1:连接床带USB,床带灯亮起
        </div>
        <div class="stepOneRemark">
          蓝灯
        </div>
      </div>

      <div class="step">
        <div class="stepTitle">
          步骤2:连接床带WIFI,前缀:kcloudM1_,密码:casnetvi
          <div class="step step-extension">
            <div class="stepTitle" style="color:#FF96AD;">
              点击复制密码
            </div>
            <div style="width: 20px" >
            </div>
            <div class="stepTitle" style="color:#FF96AD;">
              点击前往WIFI设置
            </div>
          </div>
        </div>
      </div>

      <div class="step">
        <div class="stepTitle">
          步骤3:开始配置
        </div>
      </div>

      <div class="step">
        <div class="stepTitle" style="color: gold">
          指示灯说明:
        </div>
        <div class="stepLightRemark" style="background-color: blue">
          蓝灯:配置模式
        </div>
        <div class="stepLightRemark" style="background-color: green">
          绿灯:工作模式
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'badBeltOperation'
}
</script>

<style lang="scss" scoped>

.badBeltOperationBGView {
  width: 100%;
  background-color: white;

  .badBeltOperation {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    width: calc(100% - 20px);
    transform: translate(10px);
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      width: 100%;
      height: 20px;
      line-height: 20px;
      font-size: 15px;

    }

    .step-extension {
      justify-content: center;
    }

    .step {
      width: 100%;
      display: flex;
      margin-top: 5px;

      .stepTitle-extension {
        max-width: calc(100% - 60px);
      }

      .stepTitle {
        line-height: 20px;
        font-size: 13px;
        color: silver;
        word-wrap: break-word;
      }

      .stepOneRemark {
        margin-left: 10px;
        width: 50px;
        height: 20px;
        line-height: 20px;
        font-size: 13px;
        background-color: blue;
        color: white;
        text-align: center;
      }

      .stepLightRemark {
        margin-left: 10px;
        height: 20px;
        line-height: 20px;
        font-size: 13px;
        color: white;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
      }

    }

  }
}


</style>
