<template>
  <div class="socketConfigBGView">
    <div class="socketConfig">
      <div class="headTitle">
        预设配置
      </div>
      <button class="content" v-for="(item) in configList" @click="showInPutView(item.matchType, item.title, item.content, item.dataKey)">
        <div class="contentTitle">
          {{ item.title }}
        </div>
        <div class="contentText">
          {{ item.content }}
        </div>
      </button>

      <div class="actionContent">
        <button class="actionTitle" @click="sendSocketConfig">
          发送设置
        </button>
        <button class="actionTitle" @click="resetSocketConfig">
          重置
        </button>
        <button class="actionTitle" @click="saveSocketConfig">
          一键应用预设配置
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import InPut from '@/components/inPut/inPut'
export default {
  name: 'socketConfig',
  components: { InPut },
  props: {
    configList: {
      type: Array,
      default: () => {
        return []
      }
    },
    storeKey:{
      default:'setDataInfo',
    },
  },
  data() {
    return {
    };
  },
  mounted() {
    this.$nextTick(() => {

    })
  },
  methods: {
    sendSocketConfig:function() {
      this.$emit('sendSocketConfig', {})
    },
    resetSocketConfig:function() {
      this.$emit('resetSocketConfig', {})
    },
    saveSocketConfig:function() {
      this.$emit('saveSocketConfig', {})
    },
    showInPutView: function(matchType, headTitle, content, dataKey) {
      this.$emit('showInPutView', {
        headTitle: headTitle,
        contentTitle: content,
        matchType: matchType,
        dataKey: dataKey,
        inPutKey:this.storeKey,
      })
    },
  }
}
</script>

<style lang="scss" scoped>

.socketConfigBGView {
  margin-top: 5px;
  background-color: #FFE5E2;

  .socketConfig {
    margin-top: 10px;
    margin-bottom: 10px;
    width: calc(100% - 20px);
    transform: translate(10px);
    display: flex;
    flex-direction: column;

    .headTitle {
      height: 20px;
      line-height: 20px;
      text-align: left;
      color: grey;
    }

    .actionContent {
      display: flex;
      margin-top: 5px;
      justify-content: center;

      .actionTitle {
        padding-left: 10px;
        padding-right: 10px;
        line-height: 30px;
        font-size: 15px;
        color: #E93B81;
        text-align: center;
        border: 0;
        background-color: transparent;
      }
    }


    .content {
      display: flex;
      background-color: white;
      margin-top: 5px;
      border: 0;

      .contentTitle {
        padding-left: 10px;
        width: 90px;
        height: 30px;
        line-height: 30px;
        font-size: 13px;
        color: grey;
        text-align: left;
      }

      .contentText {
        padding-right: 10px;
        width: calc(100% - 90px);
        line-height: 30px;
        font-size: 13px;
        color: grey;
        text-align: right;
        word-wrap: break-word;
      }

    }

  }

}

</style>
