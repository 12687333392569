<template>
<div class="socketInfoBGView">
  <div class="socketInfo">
    <div class="actionContent-extension">
      <div class="headTitle">
        配置
      </div>
      <button class="actionButton" @click="openSocket">
        连接床带
      </button>
    </div>
    <button class="content" v-for="(item) in configList" >
      <div class="contentTitle">
        {{ item.title }}
      </div>
      <div class="contentText">
        {{ item.content }}
      </div>
    </button>

    <div class="actionContent-extension">
      <div></div>
      <button class="actionButton" @click="refreshSocket">
        刷新配置信息
      </button>
    </div>

  </div>
</div>
</template>

<script>
export default {
  name: 'socketInfo',
  props: {
    configList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  methods:{
    openSocket:function() {
      this.$emit('openSocket', {});
    },
    refreshSocket:function() {
      this.$emit('refreshSocket', {});
    },
  },
}
</script>

<style lang="scss" scoped>

.socketInfoBGView {
  margin-top: 5px;
  background-color:#F9F9F9;

  .socketInfo {
    margin-top: 10px;
    margin-bottom: 10px;
    width: calc(100% - 20px);
    transform: translate(10px);
    display: flex;
    flex-direction: column;

    .headTitle {
      height: 20px;
      line-height: 20px;
      text-align: left;
      color: grey;
    }

    .actionButton {
      border: 0;
      background-color: transparent;
      color: #E93B81;
    }

    .actionContent-extension {
      justify-content: space-between;
      align-items: center;
      display: flex;
      margin-top: 5px;
    }


    .actionContent {
      display: flex;
      margin-top: 5px;
      justify-content: center;

      .actionTitle {
        padding-left: 10px;
        padding-right: 10px;
        line-height: 30px;
        font-size: 15px;
        color: #E93B81;
        text-align: center;
        border: 0;
        background-color: transparent;
      }
    }


    .content {
      display: flex;
      background-color: white;
      margin-top: 5px;
      border: 0;

      .contentTitle {
        padding-left: 10px;
        width: 90px;
        height: 30px;
        line-height: 30px;
        font-size: 13px;
        color: grey;
        text-align: left;
      }

      .contentText {
        padding-right: 10px;
        width: calc(100% - 90px);
        line-height: 30px;
        font-size: 13px;
        color: grey;
        text-align: right;
        word-wrap: break-word;
      }

    }

  }

}

</style>
