<template>
  <div class="logBGView">
    <div class="log">
      <div class="logHead">
        <div class="headTitle">
          日志
        </div>
        <button class="actionButton">
          复制日志
        </button>
        <button class="actionButton" @click="clearLogList">
          清空日志
        </button>
      </div>
      <div class="logConnent">

        <ul class="logConnentUl">
          <li class="logConnentLi" v-for="(item) in logList">
            <div class="logText">
              {{ item }}
            </div>
            <div class="logLine"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'log',
  props: {},
  watch: {},
  data() {
    return {
      logList: [],
    }
  },
  methods: {
    updateLogList: function(dataStr) {
      this.logList.push(dataStr)
    },
    clearLogList: function() {
      this.logList = []
    }
  }
}
</script>

<style lang="scss" scoped>

.logBGView {
  margin-top: 5px;
  background-color: #F9F9F9;
  margin-bottom: 5px;

  .log {
    margin-top: 10px;
    margin-bottom: 10px;
    width: calc(100% - 20px);
    transform: translate(10px);
    display: flex;
    flex-direction: column;

    .logHead {
      justify-content: space-between;
      align-items: center;
      display: flex;
      margin-top: 5px;

      .headTitle {
        height: 20px;
        line-height: 20px;
        text-align: left;
        color: grey;
      }

      .actionButton {
        border: 0;
        background-color: transparent;
        color: #E93B81;
      }
    }

    .logConnent {
      margin-top: 5px;

      .logConnentUl {
        padding: 0;
        list-style-type: none;
        width: 100%;
        overflow: scroll;
        max-height: 200px;

        .logConnentLi {
          .logText {
            width: 100%;
            line-height: 20px;
            font-size: 13px;
            color: grey;
            word-wrap: break-word;
            width: calc(100% - 20px);
            transform: translate(10px, 0px);
          }

          .logLine {
            width: 90%;
            height: 1px;
            background-color: #cccccc;
          }
        }
      }
    }


  }

}

</style>
