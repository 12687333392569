<template>
  <div class="socketStatusBGView">
    <div class="socketStatus">
      <div class="statusTitle">
        状态信息
      </div>

      <div class="statusContent">
        <span :class="linkWIFI?'glyphicon glyphicon-ok-sign':'glyphicon glyphicon-remove-sign'"
              :style="{color:linkWIFI?'green':'red', top:'0px'}" aria-hidden="true"></span>
        <div class="statusContentTitle" :style="{color:linkWIFI?'green':'red'}">
          {{linkWIFIMsg}}
        </div>
      </div>
      <div class="statusContent">
        <span :class="linkBadBelt?'glyphicon glyphicon-ok-sign':'glyphicon glyphicon-remove-sign'"
              :style="{color:linkBadBelt?'green':'red', top:'0px'}" aria-hidden="true"></span>
        <div class="statusContentTitle" :style="{color:linkBadBelt?'green':'red'}">
          床带连接状态:{{linkBadBelt?'已连接':'未连接'}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'socketStatus',
  props: {
    linkWIFI: {
      default: false
    },
    linkBadBelt: {
      default: false
    },
    linkWIFIName:{
      default: ''
    },
  },
  computed:{
    linkWIFIMsg:function() {
      if (this.linkWIFI == false) {
        return '未连接WIFI';
      }
      return '已连接WIFI:' + this.linkWIFIName;

    },
  }
}
</script>

<style lang="scss" scoped>

.socketStatusBGView {
  width: 100%;
  background-color: white;
  margin-top: 5px;

  .socketStatus {
    width: calc(100% - 20px);
    transform: translate(10px);

    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;

    .statusTitle {
      text-align: left;
      line-height: 20px;
      font-size: 15px;
      height: 20px;
    }

    .statusContent {
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .statusContentTitle {
        margin-left: 5px;
        width: calc(100% - 20px);
        text-align: left;
        line-height: 20px;
        font-size: 13px;
      }

    }

  }
}

</style>
